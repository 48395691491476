import React from 'react'
import {BsMoon, BsSun} from 'react-icons/bs'

const Nav = (props) => {
    return (
        <nav className='py-10 pl-10 mb-12 flex justify-between flex-wrap'>
            <a href="/"><img src="/Larcomlabs-96x96.png" className='h-16 w-auto' alt=""/></a>   
            {/* <ul className='flex items-center font-mina text-3xl text-blue-600'>
              <a href="#skills"><li className='hover:text-orange-600 cursor-pointer mx-5'>Skills</li></a>
              <li className='hover:text-orange-600 cursor-pointer mx-5'>Projects</li>
              <li className='hover:text-orange-600 cursor-pointer mx-5'>Resume</li>
            </ul>       */}
            <div className='flex items-center'>
                {props.dark ?
                  <BsSun className='cursor-pointer text-2xl dark:text-white mx-10' onClick={() => {
                    props.darkSetter(!props.dark)
                  }}/>
                :
                  <BsMoon className='cursor-pointer text-2xl dark:text-white mx-10' onClick={() => {
                    props.darkSetter(!props.dark)
                  }}/>
                }
                {/* <a 
                  className='text-lg bg-gradient-to-r from-blue-500 text-white px-4 py-2 rounded-md ml-6 font-mina
                   hover:from-orange-500' 
                  href="#updates">Whats new?
                </a> */}
            </div>
        </nav>
    )
}

export default Nav;