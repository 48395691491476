import React from 'react'

const MiniSkillCard = ({skill}) => {

    function getSkillName() {
        let hyphenated = skill.replace(" ", "-").toLowerCase()
        return `/${hyphenated}.png`
    }
    return (
    <div className='flex rounded-md shadow-lg px-2 py-1 m-0.5 bg-slate-200
        dark:bg-slate-600'>
        <img src={getSkillName()} alt="" className='h-6 w-auto mx-1'/>
        <p className='dark:text-white text-md lg:text-xl font-mina'>{skill}</p>
    </div>
  )
}

export default MiniSkillCard