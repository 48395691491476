import React from 'react';

const SkillCard = ({item, dark}) => {
    return (
        <div className='flex-col p-5 shadow-lg bg-white rounded-lg justify-center
            bg-gradient-to-br from-slate-400 to-slate-200
            dark:from-slate-700 dark:to-slate-500 h-36 w-32
            lg:h-60 lg:w-56'>
            {!dark && item.darkfilename? 
                <img className='h-12 lg:h-32 w-auto mx-auto' src={item.darkfilename} alt="" />
                :
                <img className='h-12 lg:h-32 w-auto mx-auto' src={item.filename} alt="" />
            }
            <h2 className='font-mina dark:text-white text-md lg:text-2xl my-3'>{item.skill}</h2>    
        </div>
    )
}

export default SkillCard;