import React from "react";
import MiniSkillCard from "./MiniSkillCard";

const ProjectCard = ({item}) => {
    return (
        <div className="flex flex-col p-5 shadow-lg rounded-lg bg-gradient-to-br from-slate-400 to-slate-200
            dark:from-slate-700 dark:to-slate-500 mx-auto sm:min-w-full sm:max-w-full">
            <img className='h-48 xl:h-60 w-48 xl:w-60 rounded-full mx-auto'src={item.projectImg} alt="" /> 
            <div className="text-center px-5">
                <h2 className="text-2xl lg:text-4xl font-mina text-blue-500 dark:text-blue-300 my-5">{item.projectName}</h2>
                <p className="dark:text-white text-xl font-mina">{item.projectDesc}</p>
            </div>
            <div className="mx-5 my-10 lg:mx-20 lg:my-5 dark:text-white">
                <h2 className="text-xl lg:text-2xl mb-3 font-mina text-blue-500 dark:text-blue-300">Technologies Used</h2>
                <div className="flex flex-wrap gap-1 justify-center">
                    {item.skills.map((skill, index) => {
                        return (
                            <MiniSkillCard key={index} skill={skill}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
    
}

export default ProjectCard;