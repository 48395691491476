import React from "react";
import skills from "../resources/skills.json"
import SkillCard from "../components/SkillCard"

const SkillsSection = ({darkMode}) => {
    return (
        <section id="skills" className="text-center mt-20 lg:mt-60">
            <h2 className="text-blue-500 text-2xl lg:text-5xl font-mina my-10">Skills & Technologies</h2>
            <h3 className="my-10 text-xl lg:text-3xl font-mina dark:text-white">Languages</h3>
            <div className="flex flex-wrap gap-5 lg:gap-10 text-center justify-center">
                {skills.languages.sort((a,b) => b.exp-a.exp).map((item, index) => {
                    return(
                        <SkillCard key={index} item={item} dark={darkMode}/>
                    )
                })}   
            </div>

            <h3 className="my-10 text-xl lg:text-3xl font-mina dark:text-white">Frameworks</h3> 
            <div className="flex flex-wrap gap-5 lg:gap-10 text-center justify-center">
                {skills.frameworks.map((item, index) => {
                    return(
                        <SkillCard key={index} item={item} dark={darkMode}/>
                    )
                })}   
            </div>

            <h3 className="my-10 text-xl lg:text-3xl font-mina dark:text-white">Industry Tools</h3>  
            <div className="flex flex-wrap gap-5 lg:gap-10 text-center justify-center">
                {skills.industry.map((item, index) => {
                    return(
                        <SkillCard key={index} item={item} dark={darkMode}/>
                    )
                })}   
            </div>

            <h3 className="my-10 text-xl lg:text-3xl font-mina dark:text-white">Concepts</h3>  
            <div className="flex flex-wrap gap-5 lg:gap-10 text-center justify-center">
                {skills.concepts.map((item, index) => {
                    return(
                        <SkillCard key={index} item={item} dark={darkMode}/>
                    )
                })}   
            </div>
        </section>
    )
}

export default SkillsSection;