import React from "react";
import ProjectCard from "../components/ProjectCard";
import UpcomingProject from "../components/UpcomingProject";
import projects from "../resources/projects.json"

const Projects = () => {
    return (
        <section className="text-center mt-20 lg:mt-60">
            <h2 className="text-blue-500 font-mina text-2xl lg:text-5xl my-10">Projects</h2>
            <div className="grid xl:grid-cols-3 gap-10 xl:px-20">
                {projects.map((item, index) => {
                    return (
                        <ProjectCard key={index} item={item}/>
                    )
                })}
                <UpcomingProject/>
            </div>
            
            
        </section>
    )
}

export default Projects;