import Nav from "./components/Nav"
import ContentPage from "./sections/ContentPage";
import {useState} from 'react'
import SkillsSection from "./sections/SkillsSection";
import Projects from "./sections/Projects";
import Experience from "./sections/Experience";
import Education from "./sections/Education";

function App() {
  const [darkMode, setDarkMode] = useState(true)
  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="bg-slate-300 dark:bg-slate-800">
        <header>
          <Nav darkSetter={setDarkMode} dark={darkMode}/>
        </header>
        <main className='px-10 scroll-smooth min-h-screen max-w-full pb-20'>
          <ContentPage/>
          <Projects />
          <Experience/>
          <Education />
          <SkillsSection darkMode={darkMode}/>
          
        </main>
      </div>
    </div>
  );
}

export default App;
