import React from 'react'
import MiniSkillCard from './MiniSkillCard'

const ExperienceCard = ({item}) => {

  return (
    <div className="flex flex-col xl:flex-row p-5 my-5 shadow-lg rounded-lg bg-gradient-to-br from-slate-400 to-slate-200
        dark:from-slate-700 dark:to-slate-500 2md:max-w-[45%]">
            <div className='min-w-[50%]'>
              <img className='h-48 xl:h-60 w-auto rounded-full mx-auto'src={item.companyIcon} alt="" /> 
              <h2 className="text-2xl md:text-3xl xl:text-4xl font-mina text-blue-500 dark:text-blue-300 my-2">{item.company}</h2>
              <p className="dark:text-white text-xl font-mina">{item.roleTitle}</p>
              <p className='dark:text-white text-xl font-mina'>{item.timeframe}</p>
            </div>
            
            <div className="text-center">

              {item.description?
                  <div className='px-5'>
                    <h2 className="text-2xl lg:text-3xl font-mina text-blue-500 dark:text-blue-300 my-5">Responsibilities</h2>
                    <ul className='list-disc text-start flex flex-col justify-center sm:px-[25%] xl:px-0'>
                      {item.description.map((desc, index) => {
                        return (
                          // Text will be xl size when in flex-col or big enough in flex-row
                          <li className="dark:text-white text-lg md:text-xl xl:text-xl font-mina" key={index}>{desc}</li>
                        )
                      })}
                    </ul>  
                  </div>
                :
                null
              }
              
              <h2 className="text-2xl lg:text-3xl font-mina text-blue-500 dark:text-blue-300 my-5">Technologies Used</h2>
              <div className=' flex flex-wrap gap-1 justify-center'>
                {item.skills.map((item, index) => {
                  return (
                    <MiniSkillCard skill={item} key={index}/>
                  )
                })}
              </div> 
            </div>
      </div>
  )
}

export default ExperienceCard