import React from 'react'
import exp from '../resources/exp.json'
import ExperienceCard from '../components/ExperienceCard'

const Experience = () => {
  return (
    <section className="text-center mt-20 lg:mt-60">
        <h2 className="text-blue-500 font-mina text-2xl lg:text-5xl my-10">Professional Experience</h2>
        <div className="flex flex-wrap justify-center lg:px-10 mx-auto gap-10">
            {exp.jobs.map((item, index) => {
                return (
                    <ExperienceCard item={item} key={index}/>
                )
            })}
        </div>
        
        
    </section>
  )
}

export default Experience