import React from 'react';

const UpcomingProject = () => {
    return (
        <div className='border-4 border-dashed border-black dark:border-white rounded-2xl p-5 mx-auto sm:min-w-full sm:max-w-full'>
            <h2 className='mt-20 font-mina dark:text-white text-4xl'>Upcoming Project</h2>
            <p className='mt-10 text-2xl dark:text-white'>Another project will be added here once available.</p>
        </div>
    )
}

export default UpcomingProject;