import React from 'react'

const EducationCard = ({item}) => {
  return (
    <div className="flex flex-col flex-1 p-5 my-5 lg:m-5 shadow-lg rounded-lg bg-gradient-to-br from-slate-400 to-slate-200
        dark:from-slate-700 dark:to-slate-500 mx-auto">
            <img className='h-48 xl:h-60 w-48 xl:w-60 rounded-full mx-auto' src={item.schoolIcon} alt="" /> 
            <div className="text-center px-10">
                <h2 className="text-2xl lg:text-4xl font-mina text-blue-500 dark:text-blue-300 my-2">{item.school}</h2>
                <p className="dark:text-white text-xl font-mina">{item.location}</p>
                <p className="dark:text-white text-xl font-mina">{item.timeframe}</p>
                <p className="dark:text-white text-xl font-mina">{item.degree}</p>
            </div>
        </div>
  )
}

export default EducationCard;