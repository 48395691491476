import React from 'react'
import {
    AiFillGithub, 
    AiFillLinkedin, 
    AiOutlineMail
} from 'react-icons/ai'

const ContentPage = () => {
    return (
        <section>
            <div className='flex justify-around flex-wrap m-8 typewriter'>
                <div className='text-center p-10'>
                    <h2 className='text-4xl md:text-6xl lg:text-8xl py-2 text-blue-500 font-bold'>Hi, I'm Damon</h2>
                    <h3 className='text-3xl md:text-4xl lg:text-5xl py-2 font-normal dark:text-white'>Software Engineer</h3>
                    <div className='text-6xl flex justify-center gap-16 py-10 mx-5 dark:text-white'>
                        <a href="https://github.com/LarcomDev" target="_blank" rel="noreferrer"><AiFillGithub/></a>
                        <a href="https://www.linkedin.com/in/damonlarcom/" target="_blank" rel="noreferrer"><AiFillLinkedin/></a>
                        {/* <a href="https://www.youtube.com/channel/UCM08wVKzUweS-y906itx_aA" target="_blank" rel="noreferrer"><AiFillYoutube/></a> */}
                    </div>
                    <div className='flex justify-center dark:text-white'>
                        <AiOutlineMail className='text-3xl md:text-5xl mr-5'/>
                        <a href="mailto: damon@larcom.dev"><h3 className='text-2xl md:text-4xl'>damon@larcom.dev</h3></a>
                    </div>
                    
                </div>
                <div className='flex justify-center bg-gradient-to-b dark:from-blue-400 from-blue-600 rounded-full overflow-hidden object-contain'>
                    <img src='/profile.png' alt=""/>
                </div>
            </div>
        </section>  
    )
}

export default ContentPage;